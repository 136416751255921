/* meta font */
@font-face {
    font-family: "Meta";
    src: url("../assets/fonts/Meta/meta_light_subset.woff") format("woff"), 
         url("../assets/fonts/Meta/meta_light_subset.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Meta";
    src: url("../assets/fonts/Meta/meta_light_italic_subset.woff") format("woff"), 
         url("../assets/fonts/Meta/meta_light_italic_subset.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Meta";
    src: url("../assets/fonts/Meta/meta_bold_subset.woff") format("woff"), 
         url("../assets/fonts/Meta/meta_bold_subset.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Meta";
    src: url("../assets/fonts/Meta/meta_bold_italic_subset.woff") format("woff"), 
         url("../assets/fonts/Meta/meta_bold_italic_subset.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
  }