@import 'variables';
@import 'fonts';
@import '~@angular/cdk/overlay-prebuilt.css';

//Global styles go here.

html, body { height: 100%; }

.lightbox-test {
  padding-right: 50px;
  padding-left: 50px;
}

body {
  font-family: $FONT_FAMILY;
  font-weight: normal;
  font-feature-settings: "lnum";
  font-variant-numeric: lining-nums;
  color: $FONT_DARK_PRIMARY;
  margin: 0;
}

a {
  text-decoration: none;
  color: $LINK;

  :hover {
    text-decoration: underline;
    color: $LINK_HOVER;
  }

  :visited {
    color: $LINK_HOVER;
  }
}

p {
  margin-top:0;
}

small {
  font-size: 12px;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    line-height:1.2;
    margin-bottom: .5rem;
}

.h1, h1 {
  font-size: 2.5rem;
}

.h2, h2 {
  font-size: 2rem;
}

.h3, h3 {
  font-size: 1.75rem;
}

.h4, h4 {
  font-size: 1.5rem;
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

.container-overview {
  display: flex;
  flex-flow: column;
  height: 100%;
}

//ported from bootstrap
.container-fluid {
  width:100%;
  // padding-right: 15px;
  // padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  flex: auto;
  overflow: auto;
  height: 100%;

  *, ::before, ::after {
    box-sizing: border-box;
  }
}

.container {
  width:100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  ::before, ::after {
    box-sizing: border-box;
  }
}

.container-tabs {
  max-width: 1200px;
  margin-bottom: 25px;
}

.container-details {
  max-width: 1440px;
  width: 770px;
  padding: 0 40px;
  margin-right: auto;
  margin-left: auto;
}

.container-header {
  width: 1440px;
  padding: 0 40px;
  margin-right: auto;
  margin-left: auto;
}

.detail-page-container {
  min-height: 100%;
  background-color: $UI_10;
}

.detail-page-sidebar-pane-container {
  //width: 350px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 100%;
  //padding-left: 40px;
}

.sidebar-back-button-padded {
  padding: 25px 20px 0 40px;
}

.sidebar-container-padded {
  font-size: 14px;
  padding: 0 20px 0 40px;
  width: 350px;

  .sidebar-collection-header-container {
    margin: 11px 0 13px;

    .sidebar-collection-header {
      color: #666;
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      vertical-align: middle;
    }
  }
}

.detail-page-content-pane-container {
  background-color: $UI_10;
  max-width: 1440px;
  width: 770px;
  padding: 0 40px 300px 40px;
  margin-right: auto;
  margin-left: auto;
}

.top-spacing {
  margin-top: 32px;
}
.top-small-spacing {
  margin-top: 18px;
}
.bottom-small-spacing {
  margin-bottom: 18px;
}

.button-spacing {
  margin-right: 10px !important;
}

.delete-ellipsis-option {
  color: $SUPPORT_FAILURE;
}

.tooltip{
  cursor: help;
}

.subdue-text {
  color: $FONT_DARK_SUBDUED;
}

.subdue-icon {
  color: $UI_40;
}

.success-text {
  color: $SUPPORT_SUCCESS;
}

.warning-text {
  color: $SUPPORT_WARNING;
}

.failure-text {
  color: $SUPPORT_FAILURE;
}

.tooltip-icon {
  font-size: 14px;
  cursor: help;
  color: $UI_40;
  display: inline-flex !important;
  vertical-align: middle;
}

.vertical-align-text {
  margin-top: auto;
  margin-bottom: auto;
}

.align-center-vertical {
  margin-top: auto;
  margin-bottom: auto;
}

.image-center{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.gapfill{
  padding-top: 2%;
}

.align-center-horizontal {
  text-align: center !important;
}

.align-right {
  text-align: right;
}

.align-bottom {
  margin-top: auto;
}

.detail-button-container {
  padding-top: 15px;
  text-align: right;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.word-break-all {
  word-break: break-all;
}

.word-wrap-break-word {
  word-wrap: break-word;
}

// flag image styles
.card-title-flag-image {
  vertical-align: middle;
  padding-left: 5px;
}

// Drag & Drop styles: drop-list-container, drop-list-item
.drop-list-container.cdk-drop-list-dragging .drop-list-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.drop-list-item {
  &:not(:first-of-type) {
    border-top: 1px solid $UI_30;
  }
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.image-drag-preview {
  .mat-card {
    padding: 20px;
  }
  .subdue-text {
    color: $FONT_DARK_SUBDUED;
  }
  .preview-image{
    height: 65px;
    width: 65px;
    padding-right: 15px;
    object-fit: cover;
    border-radius: 4px;
  }
  .card-video-overlay {
    position: absolute;
    height: 65px;
    width: 65px;
    border-radius: 4px;
    background-color: rgba($FONT_DARK_PRIMARY, .25);
    cursor: pointer;
  }
  .card-play-arrow {
    position: absolute;
    color: $FONT_LIGHT_PRIMARY;
    font-size: 24px;
    top: 21px;
    left: 21px;
   }
}

//Pill Styles
.pill {
  border-radius: 4px;
  background-color: $UI_30;
  padding: 5px 8px;
  font-size: 12px;
  height: fit-content;

  &.pill-primary {
    color: $FONT_LIGHT_PRIMARY;
    background-color: $UI_60;
  }

  &.pill-hm {
    color: $FONT_LIGHT_PRIMARY;
    background-color: $BRAND;
  }
}

// .pill {
//   height: 17px;
//   border-radius: 8.5px;
//   font-size: 10px;

//   .pill-text {
//     margin-left: 15px;
//     margin-right: 15px;
//     padding-top: 2px;
//   }
// }
.status-draft {
  background-color: $DRAFT_BACKGROUND;
  color: $DRAFT_TEXT;
}
.status-published {
  background-color: $PUBLISHED_BACKGROUND;
  color: $PUBLISHED_TEXT;
}

//Custom Scrollbar Styles
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: $SCROLLBAR_BAR $SCROLLBAR_BG;


  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
    background: $SCROLLBAR_BG;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    background: $SCROLLBAR_BG;
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $SCROLLBAR_BAR;
    border-radius: 10px;
    border: 3px solid $SCROLLBAR_BG;
  }
}

// Validation Corrections
// Corrects placeholder text color for dropdowns when invalid.
mat-form-field.ng-invalid {
  .mat-select-placeholder {
    color: $SUPPORT_FAILURE;
  }
}

.mat-snack-bar-container {
  max-width: 100% !important;
}

.alert-message-header-container {
  margin-bottom: 15px;
}

.alert-message {
  font-size: 14px;
  border-radius: 4px;
  //min-height: 30px;
  padding: 15px 24px;
}

.Success {
  background-color: $SUPPORT_SUCCESS_SUBDUE;
  color: $UI_00;
  text-align: left;
}

.Warning {
  background-color: $SUPPORT_WARNING_SUBDUE;
  color: $UI_00;
  text-align: left;
}

.Error {
  background-color: $SUPPORT_FAILURE_SUBDUE;
  color: $UI_00;
  text-align: left;
}

.Help {
  background-color: $SUPPORT_HELP_SUBDUE;
  color: $UI_00;
  text-align: left;
}

.Info {
  background-color: $UI_50;
  color: $UI_00;
  text-align: left;
}

// Headers
.detail-header-title-text {
  font-size: 24px;
  font-weight: bold;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail-header-updated-on-text {
  font-size: 12px;
  color: $FONT_DARK_SUBDUED;
}

// Animations
.ellipsis-animation span {
  opacity: 0;
  -webkit-animation: ellipsis-dot 1s infinite;
  animation: ellipsis-dot 1s infinite;
}
.ellipsis-animation span:nth-child(1) {
  -webkit-animation-delay: 0.0s;
  animation-delay: 0.0s;
}
.ellipsis-animation span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.ellipsis-animation span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
@-webkit-keyframes ellipsis-dot {
    0% { opacity: 0; }
   50% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes ellipsis-dot {
    0% { opacity: 0; }
   50% { opacity: 1; }
  100% { opacity: 0; }
}


// Product Sidebar
.sidebar-product-search {
  max-height: 400px!important;
  overflow-x: hidden!important;
  padding-right:5px;

  .sidebar-product-search-container {

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .sidebar-product-search-box-container {
      padding:10px;
      width: $SIDEBAR_SEARCH_WIDTH_LARGE;
      max-width: $SIDEBAR_SEARCH_WIDTH_LARGE;

      @media screen and (max-width: 1000px) {
        width: $SIDEBAR_SEARCH_WIDTH_MEDIUM;
        max-width: $SIDEBAR_SEARCH_WIDTH_MEDIUM;
      }

      @media screen and (max-width: 950px) {
        width: $SIDEBAR_SEARCH_WIDTH_SMALL;
        max-width: $SIDEBAR_SEARCH_WIDTH_SMALL;
      }

      position: sticky;
      position: -webkit-sticky;
      top: 0;
      background-color: $UI_00;
    }

    .sidebar-product-search-item {
      padding:10px 5px 10px 10px;

      .sidebar-product-search-image {
        width: auto;
        max-height: 30px;
      }

      .sidebar-product-search-name {
        max-width: $SIDEBAR_SEARCH_WIDTH_LARGE;

        @media screen and (max-width: 1000px) {
          max-width: $SIDEBAR_SEARCH_WIDTH_MEDIUM;
        }

        @media screen and (max-width: 950px) {
          max-width: $SIDEBAR_SEARCH_WIDTH_SMALL;
        }

        > span {
          padding-left: 15px;
          word-wrap: break-word;
          overflow-wrap: break-word;
        }
      }
    }

    .sidebar-product-search-item:hover {
      cursor: pointer;
      background-color: $UI_10;
    }

    .sidebar-search-message {
      span {
        font-size: 14px;
        color: $FONT_DARK_SUBDUED;
      }
    }
  }
}


// Remove increment/decrement buttons from number based input controls in Firefox
input[type=number] {
  -moz-appearance: textfield;
}
