// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
@import 'variables';
@import 'fonts';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$helios-web-primary: mat-palette($THEME);
$helios-web-accent: mat-palette($THEME);
// The warn palette is optional (defaults to red).
$helios-web-warn: mat-palette($THEME-DANGER);

$helios-typography: mat-typography-config(
    $font-family: $FONT_FAMILY
);

// Create the theme object (a Sass map containing all of the palettes).
$helios-web-theme: mat-light-theme($helios-web-primary, $helios-web-accent, $helios-web-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

// @include mat-base-typography($helios-typography);
// @include angular-material-typography($helios-typography);
@include mat-core($helios-typography);
@include angular-material-theme($helios-web-theme);

// Below are custom styling for the Angular Materials Components

// Input - Start
.input-search {
  div.mat-form-field-suffix {
    top: .5em;

    .mat-icon {
      color: $NON_ACTIVE;
      font-size: 24px;
    }
  }

  .mat-form-field-outline-end,
  .mat-form-field-outline-start {
    border: 2px solid currentColor;
  }

  div.mat-form-field-flex {
    padding-left: 14px;
    padding-right: 14px;
  }

  div.mat-form-field-outline {
    color: $UI_40;
  }
}

.input-search.input-search-detail {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

mat-form-field {
  width: 100%;
  font-size: 14px !important;

  &.wrapper-none {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}
div.mat-form-field-outline-thick {
  color: $UI_60 !important;
}
mat-form-field.mat-form-field-should-float{
  div.mat-form-field-outline {
    color: $UI_60;
  }
  label.mat-form-field-label{
    color: $UI_60 !important;
  }
}
div .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
div .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap,
div .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start {
  border-width: 1px;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 4px 0 12px 0;
}
mat-form-field.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 18px 0 18px;

  .mat-form-field-prefix {
    align-self: center;
    margin-right: 5px;
  }
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 0 !important;
  color: $UI_60;
}
.mat-form-field-outline-start {
  width: 13px !important;
}
.mat-form-field-label-wrapper {
  top: -18px;
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  color: $SUPPORT_FAILURE;
  caret-color: $SUPPORT_FAILURE;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: $SUPPORT_FAILURE;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: $SUPPORT_FAILURE;
}

mat-form-field.locales-select {
  .mat-form-field-wrapper {
    width: 180px;
    padding-bottom: 0;

    .mat-form-field-flex {
      padding: 5px 10px;
      border-radius: 4px;
      .mat-form-field-infix {
        border-top: none !important;
        padding-bottom: 0 !important;
      }
    }
  }
  .mat-select-arrow-wrapper {
    padding-top: 15px;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-select-value > span > span {
    padding-bottom: 5px;
    font-size: 12px;
  }
}

.input-dimension {
  div.mat-form-field-flex {
    width: 150px;
  }
}

// smaller, more compact dropdown lists.
.mat-form-field-compact {
  .mat-form-field-wrapper {
    padding-bottom: 0;

    .mat-form-field-flex {
      padding: 0 10px;

      .mat-form-field-infix {
        padding: 0 0 8px 0;
      }
    }
  }
}
// Input - End

// Tabs - Start
.mat-tab-nav-bar {
  height: 40px;
}
.mat-tab-link-container {
  .mat-tab-link {
    opacity: 1;
    font-size: 14px;
    color: $NON_ACTIVE;
    min-width: 0;
    height: 40px;
  }
  .mat-tab-link:hover {
    color: $FONT_DARK_PRIMARY;
    padding-top: 3px;
    border-bottom: 2.5px solid $FONT_DARK_PRIMARY;
    transition: .25s;
    transition-timing-function: ease-in-out;
    -webkit-transition: .25s ease-in;
    -moz-transition: .25s ease-in;
    -o-transition: .25s ease-in;
  }
  .mat-tab-label-active {
    color: $FONT_DARK_PRIMARY;
  }
  a:hover {
    text-decoration: none;
  }
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  transition: none;
  background-color: $FONT_DARK_PRIMARY;
}
// Tabs - End

// Table - Start
table.vp-table {
  width: 100%;
  border-radius: 4px;

  thead {
    background-color: transparent;
  }
  .mat-header-row {
    background-color: transparent;
  }

  th.mat-table-sticky {
    background-color: $UI_00;
    &:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
  }

  .mat-header-cell,
  .mat-cell {
    padding-left: 50px;

    &:first-child {
      border-top-left-radius: 4px;
    }

    &::last-child {
      border-top-right-radius: 4px;
    }

    &.integration-offering-detail {
      top: 350px;
    }
  }
  .mat-header-cell.align-center-vertical {
    justify-content: center;
    .mat-sort-header-container {
      justify-content: center;
    }
  }
  .mat-header-cell.align-right {
    text-align: right;
    .mat-sort-header-container {
      justify-content: right;
    }
  }
  .mat-cell.align-center-vertical {
    text-align: center;
  }
  .mat-cell.align-right {
    text-align: right;
  }
  .mat-table-sticky {
    padding-top: 15px;
  }
  .table-detail {
    overflow: hidden;
    display: flex;
  }
  td.mat-cell {
    color: $UI_50;
  }
  .table-expanded-row {
    background-color: $UI_10;
  }
  tr.table-row:not(.table-expanded-row):hover {
    background: $UI_10;
  }
  tr.table-row:not(.table-expanded-row):active {
    background: $UI_30;
  }
  tr.table-row.none-selectable {
    cursor: default;

    &:hover {
      background: $UI_00;
    }
  }
  tr.mat-header-row {
    height: 30px;
  }
  tr.mat-footer-row, tr.mat-row {
    height: 60px;
  }
  tr.offering-detail-row {
    height: 0;
  }
  tr.table-row {
    cursor: pointer;
  }
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type,
  th.mat-header-cell:first-of-type {
    padding-left: 0;
    padding-right: 0;
  }
  // Custom theme styles
  .offering-detail-row td {
    border-bottom-width: 0;
  }
  .column-align-right {
    display: flex;
    padding: 40px 0 26px 0;
    justify-content: flex-end;
  }

  // Custom for page
  &.integration-offerings-detail {
    .mat-table-sticky {
      top: 52px !important;
    }
  }
}
table.vp-detail-table {
  width: 100%;

  .mat-row {
    background-color: $UI_10;
  }

  .mat-row:last-child {
    border-bottom: none;
  }
  .mat-header-row {
    min-height: 41px;
  }
  .mat-header-cell {
    background-color: $UI_20;
  }

  .mat-cell:first-child {
    padding-left: 0;
  }
  .mat-header-cell,
  .mat-cell {
    padding-left: 0;
  }
  mat-cell:first-of-type,
  mat-footer-cell:first-of-type,
  mat-header-cell:first-of-type {
    padding-left: 24px !important;
  }
}
// Table - End

// Dialog - Start
.mat-dialog-title {
  font-weight: bold;

  small {
    font-weight: normal;
  }
}
div.mat-dialog-content {
  padding-top:8px;
}
.mat-dialog-actions {
  justify-content: flex-end;
}
// Dialog - End

// Snackbar - Start
.snackbar-success {
  background-color: $SUPPORT_SUCCESS;
}

.snackbar-failure {
  background-color: $SUPPORT_FAILURE;
}

.snackbar-warning {
  background-color: $SUPPORT_WARNING;
}

.snackbar-help {
  background-color: $SUPPORT_HELP;
}

.snackbar-success, .snackbar-failure, .snackbar-warning, .snackbar-help {
  color: #FFF;

  .mat-simple-snackbar-action {
    color: #FFF;
  }
}

.mat-snack-bar-container {
  button.mat-raised-button[disabled] {
    color: #3a4359;
    border-color: #3a4359;
  }
  button.mat-raised-button[disabled]:hover {
    border: 1px solid #3a4359;
  }
}
// Snackbar - End

// Raised Button - Start
button:focus {
  outline: none;
}

// Base button
a.mat-button {
  color: inherit;

  :hover {
    color: inherit;
    text-decoration: none;
  }
}

button.mat-raised-button {
  border: 1px solid $FONT_DARK_PRIMARY;
  border-radius: 5px;
  padding: 0 30px;
  box-shadow: none !important;
  height: 32px;
  vertical-align: middle;
  line-height: normal;
  font-weight: 700;

  &:hover {
    color: $UI_50;
    border-color: $UI_50;
  }

  &[disabled] {
    border-color: $UI_DISABLE_BUTTON_BORDER;

    &:hover{
      background-color: $UI_DISABLE_BUTTON;
      border-color: $UI_DISABLE_BUTTON_BORDER;
    }
  }

  &.icon-button {
    padding: 0 7px;
    min-width: 0;
  }
}

// Primary color button (black for Harbour)
button.mat-raised-button.primary-button {
  color: $UI_00;
  background-color: $UI_60;

  &:hover{
    background-color: $UI_50;
    border-color: $UI_50;
  }

  &[disabled] {
    background-color: $UI_DISABLE_BUTTON;
    border-color: $UI_DISABLE_BUTTON_BORDER;

    &:hover {
      cursor: default;
      background-color: $UI_DISABLE_BUTTON;
      border-color: $UI_DISABLE_BUTTON_BORDER;
    }
  }
}

button.mat-raised-button.success-button {
  color: white;
  background-color: $SUPPORT_SUCCESS;
  border: 1px solid $SUPPORT_SUCCESS_SUBDUE;

  &:hover {
    color: white;
    background-color: $SUPPORT_SUCCESS_STRENGTHEN;
    border: 1px solid $SUPPORT_SUCCESS;
  }

  &[disabled] {
    background-color: $UI_DISABLE_BUTTON;
    border-color: $UI_DISABLE_BUTTON_BORDER;

    &:hover {
      cursor: default;
      background-color: $UI_DISABLE_BUTTON;
      border-color: $UI_DISABLE_BUTTON_BORDER;
    }
  }
}

button.mat-raised-button.warning-button {
  color: white;
  background-color: $SUPPORT_WARNING;
  border: 1px solid $SUPPORT_WARNING_SUBDUE;

  &:hover {
    color: white;
    background-color: $SUPPORT_WARNING_STRENGTHEN;
    border: 1px solid $SUPPORT_WARNING;
  }

  &[disabled] {
    background-color: $UI_DISABLE_BUTTON;
    border-color: $UI_DISABLE_BUTTON_BORDER;

    &:hover {
      cursor: default;
      background-color: $UI_DISABLE_BUTTON;
      border-color: $UI_DISABLE_BUTTON_BORDER;
    }
  }
}

button.mat-raised-button.danger-button {
  color: white;
  background-color: $SUPPORT_FAILURE;
  border: 1px solid $SUPPORT_FAILURE_SUBDUE;

  &:hover {
    color: white;
    background-color: $SUPPORT_FAILURE_STRENGTHEN;
    border: 1px solid $SUPPORT_FAILURE;
  }

  &[disabled] {
    background-color: $UI_DISABLE_BUTTON;
    border-color: $UI_DISABLE_BUTTON_BORDER;

    &:hover {
      cursor: default;
      background-color: $UI_DISABLE_BUTTON;
      border-color: $UI_DISABLE_BUTTON_BORDER;
    }
  }
}

button.mat-button.dropdown-button {
  .mat-icon {
    width: $DROPDOWN_ICON_WIDTH;
    height: $DROPDOWN_ICON_HEIGHT;
  }
}
// Raised Button - End

// Tooltip - Start
.mat-tooltip {
  background-color: $TOOLTIP;
  color: $UI_00;
  font-size: 14px;
}

// Apply to matTooltipClass when you want multiline without truncation on tooltips.
.tooltip-multiline {
  white-space: pre-line !important;
  word-break: break-all !important;
}

.tooltip-wide-700 {
  max-width: 700px !important;
  width: 700px !important;
}
// Tooltip - End

// Button Toggle Group - Start

.mat-button-toggle-collapsed .mat-button-toggle-label-content {
  line-height: 32px !important;
}

.sidebar-grid-options {
  border: none;
  width: 100%;

  .mat-button-toggle {
    border: none !important;
    color: $NON_ACTIVE;
    font-weight: bold;
    font-size: 14px;
    height: 35px;
    border-radius: 4px;
    padding: 8px 15px;
    margin-top: 10px;

    // &:not(:first-child) {
    //   margin-top: 10px;
    // }

    .mat-button-toggle-button{
      line-height: normal;

      .mat-button-toggle-label-content{
        padding: 0;
        text-align: left;
        line-height: normal;
      }
    }
  }
  .mat-button-toggle:hover {
    color: $FONT_DARK_PRIMARY;
    transition: .25s;
    transition-timing-function: ease;
    -moz-transition: .25s;
    background-color: $UI_20;
  }
  .mat-button-toggle.mat-button-toggle-checked:hover {
    color: $FONT_DARK_PRIMARY;
    background-color: $UI_30;
  }
  .mat-button-toggle-checked {
    color: $FONT_DARK_PRIMARY;
    background-color: $UI_30;
  }
  .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
    opacity: 0;
  }
  .mat-icon {
    font-size: 22px;
    margin-right: 15px;
  }
}

mat-button-toggle.raised-button:focus {
  outline: none;
}

mat-button-toggle.raised-button {
  .mat-button-toggle-label-content {
    border: 1px solid $FONT_DARK_PRIMARY;
    border-radius: 5px;
    padding: 0 30px;
    box-shadow: none !important;
    height: 32px;
    line-height: 32px;
    font-weight: 700;
    font-size: 14px;
  }
}

mat-button-toggle.raised-button.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
mat-button-toggle.raised-button.mat-button-toggle-group-appearance-standard {
  border: none;
}
// Button Toggle Group - End

// Card - Start
mat-card.app-card {
  box-shadow: none !important;
  border: 1px solid $UI_30;
  padding: 0;
  color: $FONT_DARK_PRIMARY;

  mat-card-header.mat-card-header {
    background-color: $UI_20;
    border-radius: 4px 4px 0 0;
    padding: 10px 20px;

    div.mat-card-header-text {
      margin-top: auto;
      margin-bottom: auto;

      mat-card-title.mat-card-title {
        margin-top: auto;
        margin-bottom: auto;

        h6.h6 {
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }
  }
  img.mat-card-avatar {
    max-width: 25px;
    max-height: 25px;
  }
  mat-card-title{
    color: $FONT_DARK_PRIMARY;
  }
}

.display-card {
  .card-header {
    min-height: 52px;
  }
  .card-header div:last-child,
  .card-content div.card-content-more:last-child {
    text-align: right;
  }
  .card-header div,
  .card-content div {
    flex: 1 1 auto;
  }
  .card-content {
    padding: 5px 20px;
    font-size: 14px;
    border-top: 1px solid $UI_30;
    min-height: 51px;

    &.selectable {
      cursor: pointer;

      &:hover {
        background-color: $UI_10;
      }
    }
  }
  .empty-card-content {
    min-height: 51px;
    color: $UI_40;
  }
}

.card-section {
  padding-top: 25px;

  .section-title {
    padding-left: 25px;
    margin-bottom: 0;
  }

  .section-sub-title {
    padding-left: 25px;
    font-size: 14px;
    color: $FONT_DARK_SUBDUED;
  }

  .detail-card-readonly {
    margin-top: 15px;

    .card-content {
      min-height: 65px;
      padding: 20px 24px;

      &.card-content-image {
        padding: 20px 24px;
      }

      &:not(:first-of-type) {
        border-top: 1px solid $UI_30;
      }

      &.card-border {
        border-top: 1px solid $UI_30;
      }

      .card-title {
        padding-top: 4px;
      }

      .card-sub-title {
        font-size: 12px;
        padding-bottom: 5px;
        color: $FONT_DARK_SUBDUED;
      }

      .card-image-container {
        max-width: 80px;
      }

      .card-image{
        height: 65px;
        width: 65px;
        object-fit: cover;
        border-radius: 4px;
        cursor: pointer;
      }

      .card-empty-image{
        cursor: default;
      }

      & div > .card-data-name {
        color: $FONT_DARK_SUBDUED;
        word-break: break-all;
      }

      .card-video-overlay {
        position: absolute;
        height: 65px;
        width: 65px;
        border-radius: 4px;
        background-color: rgba($FONT_DARK_PRIMARY, .25);
        cursor: pointer;
      }

      .card-play-arrow {
        position: absolute;
        color: $FONT_LIGHT_PRIMARY;
        font-size: 24px;
        top: 21px;
        left: 21px;
      }

      & .none {
        color: $UI_40;
      }

      & div > .list-title {
        font-weight: bold;

        &:not(:first-of-type) {
          padding-top: 10px;
        }
      }

      & div > .list-item.list-item-config-model {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 350px;

        &:not(:first-of-type) {
          padding-top: 3px;
        }
      }
    }

    .card-accordion {
      &:not(:first-child) > .mat-expansion-panel {
        border-top: 1px solid $UI_30;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .mat-expansion-panel {
        box-shadow: none;

        .mat-expansion-panel-header {
          font-size: 14px;
          padding: 20px 25px;
          min-height: 65px;

          .card-title {
            padding-top: 4px;
          }

          .card-sub-title {
            font-size: 12px;
            color: $FONT_DARK_SUBDUED;
          }
        }
        .card-accordion-content {
          border-top: 1px solid $UI_30;
          padding-top: 10px;

          &:not(:last-child) {
            padding-bottom: 10px;
          }

          .card-title {
            font-size: 12px;
            color: $FONT_DARK_SUBDUED;
          }

          .card-link {
            overflow-wrap: break-word
          }

          .none {
            color: $UI_40;
          }
        }
      }
    }
  }

  .detail-card-edit {
    margin-top: 15px;
    background-color: $UI_00;

    .card-content {
      min-height: 65px;
      padding: 5px 24px;

      &.card-content-image {
        padding: 20px 24px;
      }

      &.card-content-image-drag {
        padding: 20px 20px 20px 0;
      }

      &.card-content-checkbox {
        padding: 5px 20px 5px 0;
      }

      &:not(:first-of-type) {
        border-top: 1px solid $UI_30;
      }

      &.card-border {
        border-top: 1px solid $UI_30;
      }

      .card-title {
        padding-top: 19px;
        padding-bottom: 18px;
      }

      .card-sub-title {
        font-size: 12px;
        padding-bottom: 2px;
        color: $FONT_DARK_SUBDUED;
      }

      div.mat-form-field-wrapper {
        padding-bottom: 0px;
      }

      div.mat-form-field-subscript-wrapper {
        margin-top: 2.5em;
      }

      .display-text {
        padding: 18px 0;
      }

      .card-input-spacing {
        padding-top: 3px;
        padding-bottom: 15px;
      }

      .card-image-container {
        max-width: 80px;
      }

      .card-image{
        height: 65px;
        width: 65px;
        object-fit: cover;
        border-radius: 4px;
        cursor: pointer;
      }

      .card-empty-image{
        cursor: default;
      }

      & div > .card-data-name {
        color: $FONT_DARK_SUBDUED;
        word-break: break-all;
      }

      .card-video-overlay {
        position: absolute;
        height: 65px;
        width: 65px;
        border-radius: 4px;
        background-color: rgba($FONT_DARK_PRIMARY, .25);
        cursor: pointer;
      }

      .card-play-arrow {
        position: absolute;
        color: $FONT_LIGHT_PRIMARY;
        font-size: 24px;
        top: 21px;
        left: 21px;
      }

      & .none {
        color: $UI_40;
      }

      & > .list-padding {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      & div > .list-title {
        font-weight: bold;

        &:not(:first-of-type) {
          padding-top: 10px;
        }
      }

      & div > .list-item.list-item-config-model {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 350px;

        &:not(:first-of-type) {
          padding-top: 3px;
        }
      }

      // & div > .list-item:not(:first-of-type) {
      //   padding-top: 3px;
      // }
    }

    .card-accordion {
      &:not(:first-child) > .mat-expansion-panel {
        border-top: 1px solid $UI_30;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .mat-expansion-panel {
        box-shadow: none;

        .mat-expansion-panel-header {
          font-size: 14px;
          padding: 20px 25px;
          min-height: 65px;

          .card-title {
            padding-top: 4px;
          }

          .card-sub-title {
            font-size: 12px;
            color: $FONT_DARK_SUBDUED;
          }
        }
        .card-accordion-content {
          border-top: 1px solid $UI_30;
          padding-top: 10px;

          &:not(:last-child) {
            padding-bottom: 10px;
          }

          .card-title {
            font-size: 12px;
            color: $FONT_DARK_SUBDUED;
          }

          .card-link {
            overflow-wrap: break-word
          }

          .card-input-spacing {
            padding-top: 3px;
          }

          div.mat-form-field-wrapper {
            padding-bottom: 0px;
          }

          .none {
            color: $UI_40;
          }
        }
      }
    }
  }
}

.card-description-readonly {
  word-wrap: break-word;
}

.card-description-edit {
  word-wrap: break-word;
}
// Card - End

// SnackBar - Start
.mat-snack-bar-container {
  &.edit-snack-bar {
    min-width: 600px;
    background-color: $TOOLTIP;
  }
}
// SnackBar - End

// Slide Toggle - Start
.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-bar {
    background-color: rgba($UI_60, 54%);
  }
  .mat-slide-toggle-thumb {
    background-color: $UI_60;
  }
}
// Slide Toggle - End

// Checkbox - Start
.checkbox-item {
  padding: 10px 0 10px 25px;
  font-size: 14px;

  &:first-of-type {
    padding: 20px 0 10px 25px;
  }

  &:last-of-type {
    padding: 10px 0 20px 25px;
  }
}

.add-product-offering-filter-checkbox .mat-checkbox-label {
  white-space: initial;
}
// checkbox - End

.mat-expansion-panel.dropdown-checkbox {
  box-shadow: none;
}
.mat-expansion-panel-header {
  font-size: 14px;

}
.dropdown-checkbox-content {
  font-size: 14px;
  padding-top: 10px;
  padding-left: 25px;

  &:not(:last-child) {
    padding-bottom: 10px;
  }
}

// Divider - Start
.mat-divider.detail {
  margin-right: 0 !important;
}
// Divider - End

// Slider - Start
.mat-slider-horizontal {
  width: 100%;
}
// Slider - End

// Radio - Start
.mat-radio-group {
  display: flex;
  flex-direction: column;

  .mat-radio-button:not(:first-child) {
    padding-top: 10px;
  }

  .mat-radio-button {
    .mat-radio-label {
      width: fit-content;
    }
  }
}
// Radio - End

//Alert - Start
.alert-message {
  .mat-icon {
    font-size: 18px;
    color: $UI-00;
    //padding-right: 25px; //- fixes an issue with confirmation box icon
    vertical-align: middle;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}
// Alert - End

// Sidebar
.sidebar-search-input-box {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 0;
    border: 1px transparent;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0;
    border: 1px transparent;
  }
}

mat-icon {
  &.loading {
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); }
}

// Sidebar - End


// Model profiles list item component, list styling
app-model-files-profile-list-item {
  display: block;

  &:not(:first-of-type) {
    border-top: 1px solid #ebebeb;
  }

  .card-content {
    min-height: 65px !important;
    padding: 20px 24px !important;

    .card-title {
      padding-top: 4px !important;
      padding-bottom: 0px !important;
    }

    .card-description {
      margin-top: 5px !important;
      min-height: 25px !important;

      .card-description-text {
        padding-right: 15px !important;
      }
    }

    .card-image-container {
      min-height: 25px !important;
      margin-right: 3px;
    }

    .card-image{
      height: 25px !important;
      width: 25px !important;
      object-fit: cover !important;
      border-radius: 4px !important;
      cursor: default !important;
      margin: auto !important;
    }
  }
}

// Model profiles list component - End
