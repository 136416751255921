//Helios-specific variables


//      Put all helios-specific variables here.



//herman miller approved style values. The link below also gives guidelines one when to use what color.
//https://picnic.hermanmiller.com/design/color

//herman miller approved fonts
$FONT_FAMILY: Meta;

//herman miller approved colors
$BRAND: rgba(226,45,0);
$BRAND_HOVER:rgba(201,40,0);

$FONT_DARK_PRIMARY: rgba(37,37,37);
$FONT_DARK_SUBDUED: rgba(37,37,37,.7);
$FONT_LIGHT_PRIMARY: rgba(255,255,255);
$FONT_LIGHT_SUBDUED: rgba(255,255,255,.7);

$LINK: rgba(13,117,203);
$LINK_HOVER: rgba(3,82,168);

$NON_ACTIVE: rgba(102, 102, 102);

$UI_PRIMARY_BUTTON_HOVER: rgba(70, 70, 70);
$UI_DISABLE_BUTTON: rgba(0, 0, 0, 0.12);
$UI_DISABLE_BUTTON_BORDER: rgba(0, 0, 0, 0.14);
$DETAIL_BUTTON: rgba(224,224,224);

$UI_00: rgba(255,255,255);
$UI_10: rgba(250,250,250);
$UI_20: rgba(246,246,246);
$UI_30: rgba(235,235,235);
$UI_40: rgba(172,172,172);
$UI_50: rgba(97,97,97);
$UI_60: rgba(37,37,37);
$UI_OVERLAY_LIGHT: rgba(37,37,37.2);
$UI_OVERLAY_DARK: rgba(37,37,37.7);

$DRAFT_TEXT: rgba(135, 107, 139);
$DRAFT_BACKGROUND: rgba(243, 231, 234);

$PUBLISHED_TEXT: rgba(0, 129, 108);
$PUBLISHED_BACKGROUND: rgba(228, 239, 233);

$SUPPORT_HELP: rgba(3,82,168);
$SUPPORT_HELP_SUBDUE: lighten( $SUPPORT_HELP, 10% );
$SUPPORT_HELP_STRENGTHEN: darken( $SUPPORT_HELP, 10% );

$SUPPORT_SUCCESS: rgba(0,129,108);
$SUPPORT_SUCCESS_SUBDUE: lighten( $SUPPORT_SUCCESS, 10% );
$SUPPORT_SUCCESS_STRENGTHEN: darken( $SUPPORT_SUCCESS, 10% );

$SUPPORT_WARNING: rgba(206,151,61);
$SUPPORT_WARNING_SUBDUE: lighten( $SUPPORT_WARNING, 10% );
$SUPPORT_WARNING_STRENGTHEN: darken( $SUPPORT_WARNING, 10% );

$SUPPORT_FAILURE: rgba(205,69,87);
$SUPPORT_FAILURE_SUBDUE: lighten( $SUPPORT_FAILURE, 10% );
$SUPPORT_FAILURE_STRENGTHEN: darken( $SUPPORT_FAILURE, 10% );

//Experience
$COLOR-LAVENDER-LIGHT: rgb(239,231,234);
$COLOR-LAVENDER-MID-LIGHT: rgb(224,207,214);
$COLOR-LAVENDER-MID: rgb(135,107,139);
$COLOR-LAVENDER-MID-DARK: rgb(110,90,107);
$COLOR-LAVENDER-DARK: rgb(55,45,53);

$TOOLTIP: rgba(31,37,50);

$SCROLLBAR_BG: $UI_30;
$SCROLLBAR_BAR: $UI_50;

$SIDEBAR_LEFT_BORDER: rgba(0,0,0);
// $SUPPORT_FAILURE_SUBDUE: rgb(251,244,244);
// $SUPPORT_FAILURE: rgb(205,69,87);
// $SUPPORT_SUCCESS_SUBDUE: rgb(228,239,233);
// $SUPPORT_SUCCESS: rgb(0,129,108);
// $SUPPORT_WARNING_SUBDUE: rgb(230,217,212);
// $SUPPORT_WARNING: rgb(190,87,42);

$SIDEBAR_SEARCH_WIDTH_LARGE: 260px;
$SIDEBAR_SEARCH_WIDTH_MEDIUM: 200px;
$SIDEBAR_SEARCH_WIDTH_SMALL: 175px;

$DROPDOWN_ICON_HEIGHT: 28px;
$DROPDOWN_ICON_WIDTH: 16px;

// Angular Material Theme
$THEME: (
    50 : #e5e5e5,
    100 : #bebebe,
    200 : #929292,
    300 : #666666,
    400 : #464646,
    500 : #252525,
    600 : #212121,
    700 : #1b1b1b,
    800 : #161616,
    900 : #0d0d0d,
    A100 : #eb6969,
    A200 : #e53c3c,
    A400 : #ee0000,
    A700 : #d40000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$THEME-PRIMARY: (
    50 : #fce6e0,
    100 : #f6c0b3,
    200 : #f19680,
    300 : #eb6c4d,
    400 : #e64d26,
    500 : #e22d00,
    600 : #df2800,
    700 : #da2200,
    800 : #d61c00,
    900 : #cf1100,
    A100 : #fff7f6,
    A200 : #ffc6c3,
    A400 : #ff9490,
    A700 : #ff7c77,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$THEME-DANGER: (
    50 : #f9e9eb,
    100 : #f0c7cd,
    200 : #e6a2ab,
    300 : #dc7d89,
    400 : #d56170,
    500 : #cd4557,
    600 : #c83e4f,
    700 : #c13646,
    800 : #ba2e3c,
    900 : #ae1f2c,
    A100 : #ffe7e9,
    A200 : #ffb4ba,
    A400 : #ff818b,
    A700 : #ff6873,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
